/* SMALL SCREENS */
@media only screen and (max-width: 1430px) {
  .hide-on-mobile {
    visibility: hidden;
  }

  p,
  div {
    text-align: center;
  }

  .home__container {
    height: 100%;
  }

  .home__main-section {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .home__search-container {
    display: flex;
    justify-content: flex-end;
  }

  .home__history-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .home__grid-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
    width: 100%;
    gap: 12px;
  }

  .home__history-header-pen-icon {
    margin-right: 0.3em;
    margin-bottom: 0.1em;
  }

  .home__history-stats-container {
    order: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .home__history-grid {
    order: 2;
    margin-top: 1%;
    display: grid;
    grid-template-columns: 1.1em repeat(31, 2.5%);
    row-gap: 0.5vw;
    column-gap: 0.5vw;
    justify-items: center;
    align-items: center;
  }

  .home__history-grid-item--no-entry {
    font-size: 0;
    background: var(--color__neutral-300);
    width: 2vw;
    height: 2vw;
  }

  .home__history-grid-item--existing-entry {
    font-size: 0;
    background: var(--color__primary-500);
    width: 2vw;
    height: 2vw;
  }

  .home__history-grid-item__placeholder {
    width: 2vw;
    height: 2vw;
    border: 1px solid var(--color__neutral-200);
    border-radius: 4px;
  }

  .home__stat-card {
    background: #ffffff;
    border: 4px solid var(--color__neutral-100);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 100%;
  }

  .home__gpt-user-prompt {
    color: black;
    width: 100%;
    height: 25px;
  }

  .home__main-section--header {
    flex-direction: column;
    gap: 16px;
  }

  .home__main-section--row {
    align-items: stretch;
    padding: 0 10%;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1430px) {
  .home__container {
    grid-template-columns: 3fr 13fr;
  }

  .home__main-section {
    margin: 40px;
    padding: 40px 80px;
    grid-column: 2;
    background: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
  }

  .home__search-container {
    display: flex;
    justify-content: flex-end;
  }

  .home__grid-stats-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  .home__history-header-pen-icon {
    margin-right: 0.3em;
    margin-bottom: 0.1em;
  }

  .home__history-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
    width: 100%;
    align-items: stretch;
  }

  .home__history-grid {
    align-self: center;
    grid-column: 1;
    margin-left: 1rem;
    display: grid;
    grid-template-columns: 6% repeat(31, 2%);
    row-gap: 0.5vw;
    column-gap: 0.5vw;
    justify-items: center;
    align-items: center;
  }

  .home__history-grid-item--no-entry {
    font-size: 0;
    background: var(--color__neutral-300);
    border-radius: 4px;
    width: 1vw;
    height: 1vw;
  }

  .home__history-grid-item--existing-entry {
    font-size: 0;
    background: var(--color__primary-500);
    border-radius: 4px;
    width: 1vw;
    height: 1vw;
  }

  .home__history-grid-item__placeholder {
    width: 1vw;
    height: 1vw;
    border: 1px solid var(--color__neutral-200);
    border-radius: 4px;
  }

  .home__stat-card {
    background: #ffffff;
    border: 4px solid var(--color__neutral-100);
    border-radius: 24px;
    display: flex;
    padding: 8px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home__main-section--section-1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    align-self: stretch;
  }

  .home__main-section--row {
    align-items: flex-start;
  }
}

.home__history-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.home__main-section--row {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
}

.home__main-section--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.home__container {
  grid-column: 1/13;
  display: grid;
  background-image: url('../../resources/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}

.home__cta {
  background: var(--gradient-3, linear-gradient(175deg, #7543e8 70%, #ff5b5b 100%));
  color: white;
  border-radius: 16px;
  border-color: none;
  text-decoration: none !important;
  text-decoration: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 16px 32px;
  gap: 8px;
}

.home__gpt-user-prompt-button-icon {
  color: white;
}

.home__gpt-user-prompt-button {
  background-color: var(--color__primary-500) !important;
}

.home__content-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 8px;
}

.home__content-card {
  background: #ffffff;
  border: 4px solid var(--color__neutral-100);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  height: 100%;
}

.home__content-card:hover {
  cursor: pointer;
}

.home_content-card-link {
  text-decoration: none;
}

.home__history-grid-item--existing-entry {
  border: none;
}

.home__gpt-user-prompt::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color__neutral-400);
  opacity: 1;
  /* Firefox */
}

.home__gpt-user-prompt:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color__neutral-300);
}

.home__gpt-user-prompt::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color__neutral-300);
}
