.about-us-container {
  grid-column: 2/12;
}

.about-us-wrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: var(--color__neutral-50);
  border-radius: 16px;
  padding: 48px 0;
  margin-top: 96px;
}

.about-us-text-wrapper {
  grid-column: 2/10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  padding: 0 5vw;
}

.about-us-title {
  margin: 0 35px;
  align-self: center;
}

.about-us__row-1 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.about-us__row-2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.about-us__row-2-img-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.about-us__found-tony-img {
  width: 60%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  justify-self: center;
  align-self: center;
}

.about-us__paragraph {
  justify-self: center;
  align-self: center;
  margin-top: 32px;
}

.about-us__column-img {
  width: 70%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  margin: 16px;
}

.img_center-large {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}


.img_center {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}

.img_center_small {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}