.retro__container {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
  padding: 24px 48px;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.retro__tag-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.retro__tag-list-container {
  height: auto;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 6em;
}

.change-category-button {
  background: none;
  border: solid 1px grey;
  border-radius: 16px;
}

.retro__category-pill {
  display: flex;
  justify-content: center;
  width: 256px;
  border-radius: 32px;
  padding: 3px 16px;
  overflow: wrap;
}

.retro__grid-box {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 2px;
  border: none;
}

.retro__grid-box:hover {
  border: solid var(--color__neutral-700) 2px;
}

.retro__grid-box--empty:hover {
  border: solid var(--color__neutral-700) 2px;
}

.retro__grid-box--empty {
  background: var(--color__neutral-400);
}

.retro__grid-box--off-calendar {
  border: solid 1px black;
  opacity: 0;
}

.carousel-nav-btn {
  background: none;
  border: none;
}

.hidden {
  display: none;
}

.carousel-control-prev {
  left: -100px; /* adjust this value as per your requirement */
}

.carousel-control-next {
  right: 100px; /* adjust this value as per your requirement */
}
/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1); This will change the arrow color to white
} */

/* hides the bootstrap dropdown arrow, so I can use my own */
.dropdown-toggle::after {
  display: none !important;
}

.scrollable-container {
  max-height: 600px; /* Adjust the max-height as needed */
  overflow-y: auto;
  padding-right: 10px; /* Optional: to prevent content from being hidden behind the scrollbar */
  padding-bottom: 32px;
}

.tree-map--entry-button {
  border-width: 1px;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none !important;
}

.jumble-button-tertiary {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  border-radius: 2px;
  background: transparent;
  border: none;
}

.refresh-button {
  background: none;
  border: none;
  margin-right: 64px;
}

.rotate {
  transform: rotate(360deg);
  transition: transform 1s ease-in-out;
}
