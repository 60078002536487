.single-entry__wrapper {
  display: flex;
  flex-direction: column;
  grid-column: 4/10;
}

.single-entry__container {
  grid-column: 1/13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.single-entry__header-container {
  width: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-bottom: 24px;
}

.single-entry__item-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin-bottom: 16px;
}

/* ####  */

.single-entry__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.single-entry__sticky-header-container {
  flex: 0;
  top: 0;
}

.sticky {
  position: sticky;
}

.single-entry__header-bottom-row {
  justify-content: space-between;
  margin-bottom: 16px;
}

.blur {
  filter: blur(2px);
}

.single-entry__text-field {
  background-color: rgb(0, 0, 0, 0);
  resize: none;
  border: none;
  width: 100%;
}

.single-entry__assistant-field {
  background: var(--color__primary-50);
  padding: 16px 40px 16px 16px;
  border-radius: 8px;
  position: relative;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.jumble-bot-content__delete-content-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  /* border-radius: 4px; */
  /* height: 1.5em; */
  /* width: 1.5em; */
  /* margin-right: 4px; */

  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: var(--color__primary-300);
}

.single-entry__assistant-field--light-bg {
  background: var(--color__primary-50);
  transition: background-color 600ms;
}

.single-entry__assistant-field--neutral-bg {
  background: var(--color__neutral-50);
  transition: background-color 600ms;
}

.single-entry__assistant-field--dark-bg {
  background: var(--color__primary-700);
  transition: background-color 600ms;
}

.entry-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 45vh;
}

.single-entry__text-field::-webkit-scrollbar {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.single-entry__update-buttons-container {
  display: flex;
  align-items: flex-end;
}

.single-entry__delete-button {
  margin-right: 8px;
  background-color: var(--color__secondary-200);
}

.single-entry__progress-bar-container {
  height: 1em;
  display: grid;
  grid-template-columns: repeat(32, 1fr);
}

.single-entry__progress-bar-unit {
  border-radius: 4px;
}

.single-entry__progress-bar-word-count {
  align-self: end;
}

.single-entry__progress-bar-unit--not-done {
  background-color: var(--color__neutral-200);
}

.single-entry__progress-bar-unit--complete {
  background-color: var(--color__success-400);
}

.single-entry__item-date-string {
  padding-left: 2px;
}

.back-button {
  border-radius: 68px;
  width: 48px;
  height: 48px;
  border: none;
}

.upper-right-fixed {
  position: fixed;
  right: 2em;
  top: 2em;
}

.upper-left-fixed {
  position: fixed;
  left: 2em;
  top: 2em;
}

.bottom-right-fixed {
  position: fixed;
  right: 2em;
  bottom: 2em;
}

.back-button__icon {
  color: var(--color__neutral-400);
}

.back-button__saving-spinner {
  color: var(--color__neutral-400);
  text-align: center;
  height: 1em;
  width: 1em;
}

.color-picker {
  list-style-position: inside;
  list-style-type: none;
}

.jumble-bot-switch {
}

.settings__color-selector-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding-left: 16px;
}

.settings__color-container {
  list-style-type: none;
  display: inline;
}

.settings__color {
  border: 1px solid black;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.single-entry__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* LARGE SCREEN */
@media only screen and (min-width: 1250px) {
  .single-entry__header-bottom-row {
    display: flex;
    flex-direction: row;
  }

  .single-entry__progress-bar-container {
    align-self: end;
    width: 75%;
  }

  .single-entry__progress-bar-unit {
    margin-right: 4px;
  }
}

/* SMALL SCREEN */
@media only screen and (max-width: 1250px) {
  .single-entry__header-bottom-row {
    display: flex;
    flex-direction: column;
  }

  .single-entry__progress-bar-container {
    margin-bottom: 1em;
    width: 85%;
  }

  .single-entry__progress-bar-unit {
    margin-right: 0.1em;
  }
}

.twitter-share-button {
  position: relative;
  height: 20px;
  width: 73px;
  box-sizing: border-box;
  padding: 1px 12px 1px 12px;
  background-color: #1d9bf0;
  color: #fff;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  font: normal normal normal 12px/18px 'Helvetica Neue', Arial, sans-serif;
}

.twitter-share-button:hover {
  background-color: #147dc2;
  text-decoration: none;
  color: #fff;
}

.mentor__nav--filter {
  width: auto;
  height: 40px;
  color: var(--color__neutral-500);
  background-color: white;
  border: 1px solid var(--color__neutral-200);
  border-radius: 40px;
}
