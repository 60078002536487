.feedback-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.feedback-user-entry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}
