/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .conversation-container {
    height: 160px;
  }

  .user-conversation-entry-container {
    flex-direction: column;
    height: 112px;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .conversation-container {
    height: 263px;
  }
  .user-conversation-entry-container {
    height: 71px;
  }
}

.prompt-card-box-shadow {
  box-shadow: 0px 5px 60px 20px rgba(117, 67, 232, 0.4) !important;
}

.home__prompt-container {
  background: var(--shades-white, #fff);
  border-radius: 24px;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.conversation-container {
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow-y: auto;
}

.conversation-container--gpt-prompt-card {
  height: 100%;
}

.gpt-prompt-card__fixed-button-container {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  height: 32px;
  margin: 4px 4px 0 0;
}

.clear-conversation-button {
  padding: 8px 8px;
  border-radius: 8px;
  background: var(--color__secondary-200);
  color: var(--color__neutral-700);
  border: none;
  height: 32px;
}

.opaque {
  opacity: 0.3;
}

.create-entry-button {
  padding: 4px 8px;
  border-radius: 8px;
  background: linear-gradient(180deg, #9169ed 14.29%, #ff5b5b 171.43%);
  color: var(--color__neutral-50);
  border: none;
}

.user-conversation-entry-container {
  padding: 16px 24px;
  gap: 16px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background-color: var(--color__neutral-50);
  border-radius: 0px 0px 24px 24px;
  border: none;
}

.user-conversation-entry {
  border: none !important;
  background-color: var(--color__neutral-50) !important;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.generate-prompt-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border-radius: 8px;
  background: linear-gradient(180deg, #9169ed 14.29%, #ff5b5b 171.43%);
  color: var(--shades-white, #fff);
  border: none;
}

.assistant-pill {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--color__primary-100);
  border: none;
}

.user-pill {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: flex-end;
  border-radius: 8px;
  background: var(--color__primary-500);
  color: var(--shades-white, #fff);
}

.chat-context-link {
  text-decoration: underline;
  background: none;
  border: none;
}
