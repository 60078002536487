/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .settings__header-cta-content-container {
    flex-direction: column;
  }

  .settings__header-cta-buttons-container {
    flex-direction: column;
  }

  .settings__header-cta-content {
    padding: 24px;
  }

  .settings__section {
    flex-direction: column;
    align-items: center;
  }
  .settings__section-title-container {
    align-items: center;
  }

  .settings__chat-button {
    padding: 8px 16px;
  }

  .settings__header {
    padding: 16px 0px;
  }

  .settings__wrapper {
    padding: 0 40px 20px 40px;
    margin-top: 20px;
  }

  .settings__container {
    padding: 64px 8px;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .settings__header-cta-content {
    padding: 48px;
  }

  .settings__section-title-container {
    align-items: flex-start;
    width: 20vw;
  }

  .settings__section {
    align-items: flex-start;
  }

  .settings__chat-button {
    padding: 16px 32px;
  }

  .settings__header {
    padding: 40px 0px;
  }

  .settings__wrapper {
    padding: 0 80px 40px 80px;
    margin: 40px 40px 40px 0;
  }

  .settings__container {
    grid-column: 2;
  }
}

.settings__container {
  height: 100%;
}

.settings__header-cta-buttons-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.settings__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 2px solid var(--shades-white, #fff);
  background: rgba(255, 255, 255, 0.8);
}

.settings__section-title-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.settings__section-update-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.settings__section-nickname-update-text {
  display: flex;
  width: 362px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dark-black-400, #c7c9d9);
  background: var(--light-white-200, #fff);
}

.settings__section {
  display: flex;
  gap: 24px;
  padding-top: 24px;
  align-self: stretch;
  border-top: 1px solid var(--color__neutral-200);
}

.settings__main-title {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

.settings__header-cta-content {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.settings__header-cta-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  gap: 16px;
}

.settings__header-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  background: var(--primary-purple-50, #e8e0fb);
}

.settings__header-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

.settings__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

.settings__notification-container {
  border-radius: 16px;
  height: 80vh;
}

.settings__notification-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding: 1em;
}

.settings__nickame-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .settings__update-nickname-button {
    width: 3em;
  }
}

.upgrade-premium-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.settings__mail-icon {
  height: 16px;
  width: 16px;
  color: var(--gradient-3, linear-gradient(175deg, #7543e8 70%, #ff5b5b 100%));
}

.settings__chat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border-color: var(--color__primary-600);
  border-width: 1px;
  background-color: var(--color__primary-50);
  border-style: solid;
}

.upgrade-button-1 {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--gradient-3, linear-gradient(178deg, #7543e8 0%, #ff5b5b 100%));
  border: none;
}

.upgrade-button {
  background-color: var(--color__primary-300);
  color: var(--color__primary-900);
  border-radius: 24px;
  padding: 16px 32px;
}

.upgrade-button:hover {
  background-color: var(--color__primary-400);
  color: var(--color__primary-900);
  text-decoration: none;
}

.purchase-premium-button {
  background-color: var(--color__primary-300);
  border-radius: 5px;
  color: var(--color__primary-900);
  text-decoration: none;
  border: none;
  padding: 8px 6px;
  width: 30vw;
  height: 4em;
}

.purchase-premium-button:hover {
  background-color: var(--color__primary-400);
  color: var(--color__primary-900);
  text-decoration: none;
}
.generate-email-button {
  background-color: var(--color__primary-200);
  border-radius: 5px;
  color: var(--color__primary-900);
  text-decoration: none;
  border: none;
  padding: 8px 6px;
  width: 184px;
  height: 100%;
}

.copy-clipboard-button {
  /* background-color: var(--color__primary-200); */
  border-radius: 5px;
  color: var(--color__primary-900);
  text-decoration: none;
  border: none;
  padding: 6px 6px;
}

.copy-clipboard-button--copied {
  background-color: var(--color__neutral-400);
}

.copy-clipboard-button--default {
  background-color: var(--color__success-400);
}

.clickable-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
  color: var(--color__primary-700);
}

.manage-sub-button {
  background-color: var(--color__neutral-400);
  border-radius: 5px;
  color: var(--color__primary-900);
  text-decoration: none;
  border: none;
  padding: 8px 6px;
  width: 184px;
  height: 100%;
}
