/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .carousel-item-container {
    grid-template-columns: 6fr 19fr;
    gap: 32px;
    width: 50vw;
    padding: 51px 38px 40px 37px;
  }

  .carousel-icon {
    width: 180px;
    height: 180px;
  }

  .carousel-item--text-container {
    justify-content: space-around;
  }

  .carousel-cta-icon {
    height: 180px;
    width: auto;
  }

  .carousel-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 4px;
  }

  .carousel-cta-button {
    width: 256px;
    align-self: flex-start;
  }
}




/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {

  /* SMALLER SCREENS */
@media only screen and (max-width: 900px) {
  .carousel-item-container {
    grid-template-rows: 4fr 6fr;
    height: auto;
    overflow: scroll;
    width: 50vw;
    padding: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .carousel-item-container {
    grid-template-rows: 4fr 6fr;
    height: auto;
    overflow: scroll;
    width: 30vw;
    padding: 16px;
  }
}

  .carousel-icon {
    width: 128px;
    height: 128px;
    justify-self: center;
    padding-top: 16px;
  }

  .carousel-item--text-container {
    justify-content: center;
    gap: 8px;
  }

  .carousel-cta-icon {
    align-self: center;
    height: 128px;
    width: auto;
  }

  .carousel-title-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .carousel-cta-button {
    width: auto;
    margin-top: 16px;
  }
}

.carousel-title-container {
  width: 100%;
}


.carousel-item-container {
  border-radius: 16px;
  background: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  transition: transform 750ms cubic-bezier(0.39, 0.575, 0.565, 1), opacity 450ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.carousel-item--text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-item--text-container--start {
  align-items: flex-start;
}

.carousel-item--text-container--centered {
  align-items: center;
}

.retro--button-change-range {
  background: none;
  border: solid 1px grey;
}

.carousel-nav-guide {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color__neutral-300);
  border: none;
}

.carousel-nav-guide--selected {
  background-color: var(--color__neutral-800);
}

.carousel-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
