/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .choose-plan-view {
    grid-column: 2/12;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }

  .choose-plan-wrapper {
    grid-column: 2/6;
  }
  .choose-plan-image {
    height: 580px;
    width: auto;
    grid-column: 7/10;
    margin-top: 80px;
  }
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .choose-plan-view {
    grid-column: 1/13;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }

  .choose-plan-wrapper {
    margin-top: 48px;
    margin: 64px 16px 0 16px;
    grid-column: 1/12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .choose-plan-image {
    height: 15%;
    width: auto;
    grid-column: 1/13;
    place-self: center;
    align-self: flex-start;
  }
}

.encouragement-msg-container {
  text-align: left;
}

.selected-plan {
  background-color: var(--color__primary-300) !important;
}

.plan-form__button {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  padding: 8px 16px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 4px;
  background-color: var(--color__neutral-100);
  border: none;
}

.plan-form__cancel-button {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid var(--color__primary-600);
  flex: 1 0 0;
  align-self: stretch;
  width: calc((100% - 16px) / 2);
}

.plan-form__checkout-form {
  display: flex;
  align-self: stretch;
  width: calc((100% - 16px) / 2);
  justify-content: center;
  align-items: center;
}

.plan-form__checkout-button {
  width: 100%;
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--color__primary-800, #290d6b);
  align-self: stretch;
  border: 2px solid var(--color__primary-800);
}

.plan-form__footer-container {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
}

.premium-features-title {
  margin: 8px 0 0 4px;
}

.premium-features-grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  grid-template-rows: auto;
  width: 100%;
}

.premium-feature-pill {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: var(--color__neutral-200);
  padding: 4px 8px;
  border-radius: 8px;
}

.subscription-caption-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  gap: 2px;
}

.plan-form__header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
  width: 100%;
}

.plan-form__logo {
  width: 30px;
  height: auto;
}
