/* SMALL SCREEN */
@media only screen and (max-width: 1250px) {
  .text {
    font-family: 'Inter';
    font-style: normal;
  }

  .text--display-1 {
    font-size: 54px;
    line-height: 60px;
  }

  .text--display-2 {
    font-size: 45px;
    line-height: 54px;
  }

  .text--heading-1 {
    font-size: 36px;
    line-height: 42px;
  }

  .text--heading-1-small {
    font-size: 25.5px;
    line-height: 30px;
  }

  .text--heading-2 {
    font-size: 29.25px;
    line-height: 35.25px;
  }

  .text--heading-2-small {
    font-size: 24.75px;
    line-height: 30px;
  }

  .text--heading-3 {
    font-size: 24.75px;
    line-height: 30px;
  }

  .text--heading-3-small {
    font-size: 21px;
    line-height: 25.5px;
  }

  /* TODO replace this class with h4 tags */
  .text--heading-4 {
    font-size: 21px;
    line-height: 25.5px;
  }

  h4 {
    font-size: 21px !important;
    line-height: 25.5px !important;
  }

  .text--heading-4-small {
    font-size: 17.25px;
    line-height: 21px;
  }

  .text--heading-4-small-bold {
    size: 23px;
    line-height: 33px;
  }

  .text--subheading {
    font-size: 15px;
    line-height: 18px;
  }

  .text--paragraph-1 {
    font-size: 10.5px;
    line-height: 12.75px;
  }

  .text--paragraph-2 {
    font-size: 12px;
    line-height: 14.5px;
  }

  .text--paragraph-3 {
    font-size: 13.5px;
    line-height: 16.5px;
  }

  .text--caption {
    font-size: 9px;
    line-height: 10.5px;
  }

  .text--body-large {
    font-size: 15px;
    line-height: 22.5px;
  }

  .text--body-medium {
    font-size: 12px;
    line-height: 18px;
  }

  .text--body-extra-small {
    font-size: 8.25px;
    line-height: 12px;
  }

  h2 {
    font-size: 29.25px;
    line-height: 35.25px;
  }

  .text--regular-weight {
    font-weight: 300;
  }

  .thin {
    font-weight: 225;
  }

  .text--uppercase {
    text-transform: uppercase;
  }

  .text--underline {
    text-decoration: underline;
  }
}

/* LARGE SCREEN */
@media only screen and (min-width: 1250px) {
  .text {
    font-family: 'Inter';
    font-style: normal;
  }

  .text--display-1 {
    font-size: 72px;
    line-height: 80px;
  }

  .text--display-2 {
    font-size: 60px;
    line-height: 72px;
  }

  .text--heading-1 {
    font-size: 48px;
    line-height: 56px;
  }

  .text--heading-1-small {
    font-size: 34px;
    line-height: 40px;
  }

  .text--heading-2 {
    font-size: 39px;
    line-height: 47px;
  }

  .text--heading-2-small {
    font-size: 33px;
    line-height: 40px;
  }

  .text--heading-3 {
    font-size: 33px;
    line-height: 40px;
  }

  .text--heading-3-small {
    font-size: 28px;
    line-height: 34px;
  }

  /* TODO replace this class with h4 tags */
  .text--heading-4 {
    font-size: 28px;
    line-height: 34px;
  }

  h4 {
    font-size: 28px !important;
    line-height: 34px !important;
  }

  .text--heading-4-small {
    font-size: 23px;
    line-height: 28px;
  }

  .text--subheading {
    font-size: 20px;
    line-height: 24px;
  }

  .text--paragraph-1 {
    font-size: 14px;
    line-height: 17px;
  }

  .text--paragraph-2 {
    font-size: 16px;
    line-height: 19px;
  }

  .text--paragraph-3 {
    font-size: 18px;
    line-height: 22px;
  }

  .text--caption {
    font-size: 12px;
    line-height: 14px;
  }

  .text--body-large {
    font-size: 20px;
    line-height: 30px;
  }

  .text--body-medium {
    font-size: 16px;
    line-height: 24px;
  }

  .text--body-extra-small {
    font-size: 11px;
    line-height: 16px;
  }

  h2 {
    font-size: 39px;
    line-height: 47px;
  }

  .text--regular-weight {
    font-weight: 400;
  }

  .thin {
    font-weight: 300;
  }

  .text--uppercase {
    text-transform: uppercase;
  }

  .text--underline {
    text-decoration: underline;
  }
}

.text--bold {
  font-weight: 700;
}

.text--semi-bold {
  font-weight: 600;
}

.text--extra-bold {
  font-weight: 800;
}

.text--italic {
  font-style: italic;
}
