/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .topnav__logo {
    margin-top: '4px';
    margin-right: '16px';
  }
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .topnav__logo {
  }
}

.side-nav__item--login {
  color: var(--primary-purple-700, #531ad5);
  padding: 12px 18px;
  border-radius: 14px;
  text-decoration: none;
}

.side-nav__item--create-account:hover {
  background-color: var(--color__primary-500);
  color: white;
}

.side-nav__item--login:hover {
  color: var(--color__primary-700);
}

.side-nav__item--create-account {
  color: white;
  padding: 12px 18px;
  border-radius: 14px;
  text-decoration: none;
}

.topnav__logo {
  width: '12em';
}

.topnav__left-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
}

.topnav__faq-link,
.topnav__faq-link:hover {
  text-decoration: none;
  color: var(--color__primary-900);
}

button.topnav__link {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: 'flex';
  justify-content: 'flex-start';
  align-items: 'center';
}

.topnav__link {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.topnav__link:hover {
  color: #555;
}

.topnav__link.active {
  color: #555;
  font-weight: bold;
}

.topnav__icon {
  margin-right: 0.2em;
  height: 20px;
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .side-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    position: fixed;
    height: 64px;
    width: 100%;
    padding: 1em;
    background: none;
  }

  .side-nav__top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .side-nav__bottom {
    border-top: 1px solid gray;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .side-nav__item {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .side-nav__item:hover {
    text-decoration: underline;
    color: black;
  }
}

.side-nav__item-logo {
  width: 1em;
  color: var(--color__primary-700);
  margin-right: 8px;
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .side-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    background: none;
    overflow: auto;
  }

  .side-nav__top {
    margin: 66px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 24px;
    align-items: center;
    gap: 65px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .side-nav__top-section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    width: 15vw;
  }

  .side-nav__item {
    height: 51px;
    padding: 16px 16px 16px 24px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    text-decoration: none;
    color: black;
    display: flex;
    gap: 8px;
  }

  .side-nav__item:hover {
    text-decoration: underline;
    color: black;
  }

  .side-nav__bottom {
    margin: 0 24px 66px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
  }

  .side-nav__bottom-section1 {
    border-top: 1px solid var(--color__neutral-300);
    border-bottom: 1px solid var(--color__neutral-300);
    width: 100%;
    padding: 12px 0;
  }

  .side-nav__bottom-section2 {
    width: 100%;
    margin-top: 24px;
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
}

.side-nav__item--selected {
  background: white;
}

button.side-nav__item {
  background: none;
  border: none;
  cursor: pointer;
}

button.side-nav__item--selected {
  background: white;
  border: none;
  cursor: pointer;
}

.side-nav__name-and-plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

/* .side-nav__item-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
} */
