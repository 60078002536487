/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .modal-content {
  }
}

.modal-content {
  margin-top: 64px;
  background: none;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  overflow-y: auto;
  gap: 16px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-content::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for Firefox */
.modal-content {
  scrollbar-width: none;
}
/* Hide scrollbar for IE */
.modal-content {
  -ms-overflow-style: none;
}

.plan-form__modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(178deg, rgba(117, 67, 232, 0.3) 0%, rgba(255, 91, 91, 0.3) 100%);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0; /* Start invisible */
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
}

.plan-form__modal-backdrop.active {
  opacity: 1;
  visibility: visible;
}

.plan-form-container {
  background: var(--color__neutral-50);
  border-radius: 16px;
  padding: 24px;
}
