.single-entry__tag-spinner-container {
  width: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.single-entry__tag {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 38px;
  border: 1px solid var(--color__neutral-500);
  padding: 8px;
  min-width: 125px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-entry__tag-autogenrated {
  background-color: rgba(220, 220, 220, 0.8);
}

.tag__new-tag-without-focus {
  width: 104px;
  height: 28px;
  background: var(--color__neutral-400);
  border-radius: 38px;
  border: none;
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

/* device with mouse pointer */
@media (hover: hover) {
  .single-entry__tag-deletion-highlight:hover {
    background-color: var(--color__secondary-100);
    color: black;
  }

  .tag__new-tag-without-focus:hover {
    cursor: pointer;
  }
}

.tag__new-tag-without-focus::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

.tag__new-tag-without-focus:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.tag__new-tag-without-focus::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
