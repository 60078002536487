/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .explore__container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: 32px;
  }

  .explore__wrapper {
    margin: 40px 16px;
    padding: 40px 0px;
    background: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-self: stretch;
  }

  .train-ai-button-container {
    flex-direction: column;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .explore__container {
    grid-column: 2;
    display: grid;
  }

  .explore__wrapper {
    margin-top: 80px;
    margin: 40px;
    padding: 40px 80px;
    background: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-self: stretch;
  }
}

.explore__main-title {
  margin-bottom: 1em;
  margin-left: 1em;
}

.explore__notification-container {
  width: 100%;
}

.train-ai-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  gap: 16px;
}

.question-button--main {
  background-color: var(--color__primary-200);
}

.question-button--main:hover {
  background-color: var(--color__primary-300);
}

.question-button {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  box-shadow: 0 3px 6px lightgrey;
  display: grid;
  place-items: center;
  font-size: 28px;
  padding: 12px;
  cursor: pointer;
}

.user-question-form-container {
  margin: 64px 32px 0 32px;
}

.embedding-progress-container {
}

.jumble-bot-response-container {
  margin: 0 32px;
  background: var(--color__primary-100);
  padding: 16px;
  border-radius: 8px;
}

.embed-entries-button-and-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

ul.jumble-bot-status-list {
  list-style-type: none;
  margin: 0 32px 32px 32px;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  /* line-height: 1.4; */
  color: #fff;
  background-color: #000;
  /* border: 2px solid #000; */
  border-bottom: none;
  border-radius: 4px;
  overflow: auto;
}

ul.jumble-bot-status-list li {
  padding: 6px;
  border-bottom: 1px solid #ccc;
}

ul.jumble-bot-status-list li:last-child {
  border-bottom: none;
}

.jumble-bot-status-container {
  position: relative;
  width: 100%;
}

.jumble-bot-status-toggle {
  position: absolute;
  top: 0;
  left: 36px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: white;
}

.response-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-with-journal-wrapper {
  width: 75%;
  align-self: center;
  height: 70vh;
}
