:root {
  /* SUCCESS */
  --color__success-50: #ecfdf5;
  --color__success-200: #a7f3d0;
  --color__success-400: #34d399;
  --color__success-500: #10b981;
  --color__success-600: #059669;
  --color__success-700: #047857;
  --color__success-800: #065f46;
  --color__success-900: #064e3b;
  /* NEUTRAL */
  --color__neutral-50: #f7f8f9;
  --color__neutral-100: #e7eaee;
  --color__neutral-200: #d0d5dd;
  --color__neutral-300: #b8c0cc;
  --color__neutral-400: #a0abbb;
  --color__neutral-500: #64748b;
  --color__neutral-600: #4a5668;
  --color__neutral-700: #2f3847;
  --color__neutral-800: #191d23;
  /* PRIMARY */
  --color__primary-50: #e8e0fb;
  --color__primary-100: #e3d9fa;
  --color__primary-200: #c8b4f6;
  --color__primary-300: #ac8ef1;
  --color__primary-400: #9169ed;
  --color__primary-500: #7543e8;
  --color__primary-600: #531ad5;
  --color__primary-700: #3e14a0;
  --color__primary-800: #290d6b;
  --color__primary-900: #150735;
  /* SECONDARY */
  --color__secondary-50: #fbe0e5;
  --color__secondary-100: #f7c0cb;
  --color__secondary-200: #f3a1b2;
  --color__secondary-300: #f08298;
  --color__secondary-400: #ec627e;
  --color__secondary-500: #e84364;
  --color__secondary-600: #c8183c;
  --color__secondary-700: #c8183c;
  --color__secondary-800: #430814;
}

.text--primary-50 {
  color: var(--color__primary-50);
}

.text--primary-100 {
  color: var(--color__primary-100);
  transition: color 600ms;
}

.text--primary-300 {
  color: var(--color__primary-300);
}

.text--primary-500 {
  color: var(--color__primary-500);
}

.text--primary-700 {
  color: var(--color__primary-700);
}

.text--primary-800 {
  color: var(--color__primary-800);
}

.text--primary-900 {
  color: var(--color__primary-900);
  transition: color 600ms;
}

.text--neutral-50 {
  color: var(--color__neutral-50);
}

.text--neutral-100 {
  color: var(--color__neutral-100);
}

.text--neutral-200 {
  color: var(--color__neutral-200);
}

.text--neutral-300 {
  color: var(--color__neutral-300);
}

.text--neutral-400 {
  color: var(--color__neutral-400);
}

.text--neutral-500 {
  color: var(--color__neutral-500);
}

.text--neutral-600 {
  color: var(--color__neutral-600);
}

.text--neutral-700 {
  color: var(--color__neutral-700);
}

.text--neutral-800 {
  color: var(--color__neutral-800);
}

.text--success-600 {
  color: var(--color__success-600);
}

.text--success-900 {
  color: var(--color__success-900);
}

.text--white {
  color: white;
}

.text--gradient-color {
  background: linear-gradient(180deg, #9169ed 14.29%, #ff5b5b 171.43%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text--gradient-color-2 {
  background: linear-gradient(178deg, #7543e8 0%, #ff5b5b 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background--primary-800 {
  background-color: var(--color__primary-800);
}

.background--primary-900 {
  background-color: var(--color__primary-900);
}

.background--primary-500 {
  background-color: var(--color__primary-500);
}

.background--primary-50 {
  background-color: var(--color__primary-50);
}

.background--primary-100 {
  background-color: var(--color__primary-100);
}

.background--neutral-50 {
  background-color: var(--color__neutral-50);
}

.background--neutral-100 {
  background-color: var(--color__neutral-100);
}

.background--neutral-200 {
  background-color: var(--color__neutral-200);
}

.background--neutral-300 {
  background-color: var(--color__neutral-300);
}

.background--neutral-400 {
  background-color: var(--color__neutral-400);
}

.background--neutral-500 {
  background-color: var(--color__neutral-500);
}

.background--neutral-600 {
  background-color: var(--color__neutral-600);
}

.background--neutral-700 {
  background-color: var(--color__neutral-700);
}

.background--neutral-800 {
  background-color: var(--color__neutral-800);
}
