@import 'Color.css';

/* Now all elements have a padding and margin of 0, unless defined by another style in the stylesheet. */
#root {
  margin: 0 auto;
  line-height: 1.5;
}

html,
body {
  height: 100%;
  overflow-y: auto !important;
  padding: 0 !important;
}

.app {
  font-size: calc(10px + 2vmin);
  background: white;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  min-height: 140vh;
}

/* 
* This style sheet utilized the Block, Element, Modifier methodology (BEM)
* Element that depends upon the block are denoted with double underscore 
* Modifier that changes the style of the block are denoted with double dash 
*/
.landingpage {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  justify-items: stretch;
  align-items: stretch;
}

.landingpage__container {
  grid-column: 2/12;
  overflow-x: hidden;
  max-width: 100vw;
}

.landingpage__section {
  grid-column: 1 / 13;
  background: var(--color__neutral-50);
  border-radius: 16px;
}

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .landingpage__footer-subtext {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  .landingpage__section {
    padding-left: 3%;
    padding-right: 3%;
  }

  .login__container {
    grid-column: 1/13;
    margin: 10% 0;
  }

  .login__title {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .login__image {
    height: 20%;
    width: auto;
    grid-column: 1/13;
    place-self: center;
    align-self: flex-start;
  }

  .signup__image {
    height: auto;
    width: 70vw;
    grid-column: 1/13;
    place-self: center;
    align-self: flex-start;
  }

  .login__button {
    margin-bottom: 48px;
  }

  .landingpage__demo-grid-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .landingpage__howitworks-container {
    grid-template-rows: 1fr 1fr 1fr;
  }

  .landingpage__hero-title {
    text-align: center;
  }

  .landingpage__hero-text {
    text-align: center;
  }

  .landingpage__hero-subtext {
    text-align: center;
  }

  .landingpage__hero-button-container {
    text-align: center;
  }

  .landingpage__hero {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .landingpage__hero-left {
    grid-row: 1 / 2;
    justify-content: center;
  }

  .landingpage__hero-image {
    grid-row: 2 / 3;
  }

  .landingpage__triplet-container {
    grid-template-rows: repeat(12, 1fr);
  }

  .landingpage__triplet-box--1 {
    grid-row: 1 / 5;
    margin-bottom: 22px;
  }

  .landingpage__triplet-box--2 {
    grid-row: 5 / 9;
  }

  .landingpage__triplet-box--3 {
    grid-row: 9/13;
    margin-top: 22px;
  }

  .landingpage__cta-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingpage__cta-text {
    align-self: center;
    text-align: center;
  }

  .landingpage__cta-container-img {
    grid-row: 1;
  }

  .landingpage__cta-overlap-image {
    margin-top: 32px;
    width: 40vw;
    height: auto;
  }

  .landingpage__cta-wrapper {
    justify-content: center;
    align-items: center;
  }

  .landingpage__hero-image {
    margin: 18px 0;
  }

  .landing-page__footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .vertical-bar {
    display: none;
  }

  .carousel-feedback-text {
    align-self: flex-start;
    text-align: start;
  }
}

.jumble-button-primary {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--primary-purple-500, #7543e8);
  border: 2px solid var(--primary-purple-500, #7543e8);
}

.jumble-button-secondary {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: white;
  border: 2px solid var(--primary-purple-600, #531ad5);
}

.login__container {
  background: var(--color__neutral-50);
  border-radius: 16px;
  padding: 2em;
  position: relative;
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .landingpage {
    margin-top: 96px;
  }

  .login__container {
    grid-column: 1/5;
    margin: 5% 0 20% 0;
  }

  .login__title {
    margin-top: 1em;
    margin-bottom: 24px;
  }

  .login__image {
    height: 650px;
    width: auto;
    grid-column: 6/10;
    margin-top: 80px;
  }

  .signup__image {
    height: 450px;
    width: auto;
    grid-column: 6/10;
    margin-top: 80px;
  }

  .landingpage__demo-grid-container {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .landingpage__demo-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 24px 0 40px;
  }

  .landingpage__howitworks-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .landingpage__hero-title {
    text-align: flex-start;
  }

  .landingpage__hero-text {
    text-align: flex-start;
  }

  .landingpage__hero-subtext {
    text-align: flex-start;
  }

  .landingpage__hero-button-container {
    text-align: flex-start;
  }

  .landingpage__hero {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }

  .landingpage__hero-left {
    grid-column: 1 / 2;
    margin-top: calc(8px + 1.5625vw);
    margin-left: calc(8px + 1.5625vw);
  }

  .landingpage__hero-image {
    grid-column: 2 / 3;
  }

  .landingpage__triplet-container {
    grid-template-columns: repeat(12, 1fr);
  }

  .landingpage__triplet-box--1 {
    grid-column: 1 / 5;
    margin-right: 22px;
  }

  .landingpage__triplet-box--2 {
    grid-column: 5 / 9;
  }

  .landingpage__triplet-box--3 {
    grid-column: 9/13;
    margin-left: 22px;
  }

  .landingpage__cta-container {
    display: grid;
    grid-template-columns: 6fr 4fr;
  }

  .landingpage__cta-container-text {
    margin-left: 48px;
    align-self: center;
  }

  .landingpage__cta-overlap-image {
    position: absolute;
    right: -130px; /* adjust this value to position the image as needed */
    top: 50%;
    transform: translateY(-50%);
    width: 38vw;
    height: auto;
  }

  .landingpage__cta-wrapper {
    justify-content: flex-start;
    align-items: center;
  }

  .landingpage__hero-image {
    margin-top: calc(8px + 1.5625vw);
  }

  .landing-page__footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
    gap: 64px;
  }

  .vertical-bar {
    border-left: 1px solid #ccc; /* Change the color and thickness of the vertical bar as needed */
    height: 24px; /* Adjust the height of the vertical bar as needed */
    margin: 0 16px; /* Adjust the margin around the vertical bar as needed */
  }
  
}

.landingpage__cta-wrapper {
  display: flex;
  gap: 8px;
}

.landingpage__hero {
  grid-column: 1 / 13;
  grid-row: 1;

  margin: calc(8px + 1.5625vw);
}

.landingpage__hero-image {
  justify-self: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  align-self: center;
  display: flex;
  justify-content: center;
}

.landingpage__hero-left {
  display: flex;
  flex-direction: column;
  justify-content: top;

}

.landingpage__hero-text {
  margin-top: 16px;
  margin-bottom: 0px;
}

.landingpage__hero-subtext {
  margin-top: 16px;
}

.landingpage__intro {
  grid-column: 1 / end;
  grid-row: 2;
  padding: 4% 8%;
  background-color: var(--color__primary-800);
  color: var(--color__primary-100);
  border-radius: 16px;
  text-align: center;
}

.landingpage__philosophy-text {
  grid-column: 1 / end;
  grid-row: 14;
  align-items: left;
  padding: 56px 48px 56px 48px;
  background-color: var(--color__primary-800);
  color: var(--color__primary-100);
  border-radius: 8px;
}

.landingpage__box {
  margin: 3.5% 0;
}

.landingpage__box-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landingpage__text {
  margin: 12px 0px 12px 0px;
  text-align: flex-start;
}

.landingpage__text--60-width {
  width: 50%;
}

.landingpage-intro__title {
  margin-bottom: 24px;
}

.landingpage__triplet-container {
  grid-row: 2;
  grid-column: 1 / end;
  display: grid;
  margin: 3.5%;
}

.landingpage__triplet-box {
  background-color: white;
  padding: 11%;
  border: 4px solid var(--color__neutral-100);
  border-radius: 24px;
  text-align: center;
  color: var(--color__primary-900);
}

.landingpage__triplet-icon {
  margin-bottom: 8px;
}

.landingpage__six-box--title {
  padding-bottom: 8px;
}

.landingpage__six-box--1 {
  margin-left: 0px;
  grid-column: 1 / 3;
}

.landingpage__six-box--2 {
  grid-column: 3/5;
}

.landingpage__six-box--3 {
  grid-column: 5/7;
}

.landingpage__six-box--4 {
  grid-column: 7/9;
}

.landingpage__six-box--5 {
  grid-column: 9/11;
}

.landingpage__six-box--6 {
  grid-column: 11 / end;
  margin-right: 0px;
}

.landingpage__howitworks-container {
  grid-row: 7;
  grid-column: 1/13;
  display: grid;
  margin: 1% 3.5% 3.5% 3.5%;
}

.landingpage__howitworks-section-1 {
  margin-left: 48px;
}

.landingpage__howitworks-section-3 {
  margin-right: 48px;
}

.landingpage__howitworks-title {
  grid-column: 6/8;
  text-align: center;
  grid-row: 6;
}

.landingpage__howitworks-section {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  border: 4px solid var(--color__neutral-100);
  border-radius: 24px;
  padding: 1.5em;
}

.landingpage__howitworks-img-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 13em;
  overflow: hidden;
}

.landingpage__howitworks-section img {
  max-width: 100%;
  height: auto;
  display: block;
}

.landingpage__howitworks-section-title {
  margin-top: 24px;
  margin-bottom: 16px;
  text-align: flex-start;
}

.landingpage__howitworks-text {
  text-align: center;
}

.landingpage__howitworks-image-sizing {
  width: 240px;
  height: auto;
  margin: auto;
}

.landingpage__features-title {
  margin-top: 56px;
  grid-column: 1/13;
  grid-row: 4;
}

.landingpage__demo-grid-container {
  grid-column: 1 / 13;
  grid-row: 5;
  margin: 3.5% 0 7% 0;
}

.landingpage__demo-image {
  width: 75%;
  overflow: hidden;
}

.landingpage__demo-image img {
  max-width: 100%;
  height: auto;
  display: block;
  border: 2px solid var(--color__neutral-200);
  border-radius: 8px;
  margin: 10%;
}

.landingpage__philosophy-title {
  grid-column: 1/13;
  grid-row: 13;
  margin-top: 56px;
}

.landingpage__cta-container {
  grid-row: 11;
  grid-column: 1 / end;
  border-radius: 16px;
  background: var(--color__primary-50);
  padding: 48px 0 48px 0;
  position: relative;
}

.landingpage__cta-container-img {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.landingpage__cta-container-img img {
  max-width: 75%;
  height: auto;
  display: block;
}

.landingpage__cta-title {
  margin-bottom: 16px;
  text-align: flex-start;
}

.landingpage__cta-text {
  margin-bottom: 32px;
}

.footer {
  grid-row: 12;
  grid-column: 1 / end;
  text-align: center;
}

.landingpage__footer-text {
  margin-top: 48px;
  margin-bottom: 24px;
  padding: 0 15%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.landingpage__footer-subtext {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingpage__footer-button-section {
  height: 3em;
  display: flex;
  justify-content: center;
}

.landingpage__footer-bitcoin-button {
  background: white;
  text-decoration: none;
  color: var(--color__primary-600);
  border: 2px solid var(--color__primary-600);
  border-radius: 4px;
  margin: 0 1%;
  height: 56px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingpage__footer-buybeer-button {
  background: white;
  text-decoration: none;
  color: var(--color__primary-600);
  border: 2px solid var(--color__primary-600);
  border-radius: 4px;
  margin: 0 1%;
  height: 56px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingpage__footer-buybeer-button:hover {
  color: var(--color__primary-600);
}

.landingpage__footer-portugal-svg {
  width: 24px;
  height: auto;
  margin-right: 8px;
  text-align: center;
}

.landingpage__footer-bottom {
  border-top: 1px solid;
  margin: 1em 0;
  padding-top: 0.81em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.landingpage__footer-bottom-left {
}

.landingpage__footer-bottom-right {
}

.topnav__button-container {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  grid-column: 4/7;
}

.topnav__log-out {
  color: var(--color__primary-700);
  background-color: white;
  border: none;
}

.sidenav__log-out {
  color: var(--color__primary-700);
  background: none;
  border: none;
  height: 16px;
  width: 16px;
}

.topnav__sign-up {
  color: var(--color__primary-700);
  background-color: white;
  border: none;
  margin-right: 2em;
}

.topnav__nav-item {
  align-self: center;
}

.topnav__title {
  text-align: left;
  color: var(--color__primary-800);
  grid-column: 1;
  text-decoration: none;
}

.topnav__title:hover {
  text-decoration: none;
  color: var(--color__primary-800);
}

.welcome-message {
  margin: 32px auto 24px auto;
  text-align: center;
  width: 85%;
}

.centered-button {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5%;
}

.login__wrapper {
  grid-column: 2/12;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  max-height: 100vh;
}

.login__button {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 140px !important;
  margin-bottom: 148px;
  padding: 0 14px;
}

.login__email-group {
  margin-bottom: 20px;
}

.login__email-label {
  margin-bottom: 6px !important;
}

.login__password-group {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.login__password-label {
  margin-bottom: 6px !important;
}

.login__sign-up-cta {
  position: absolute;
  bottom: 2em;
}

.signup__button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.calendar__wrapper {
  grid-column: 2/12;
  margin-left: 4em;
}

.calendar-month-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 100px);
  padding-top: 32px;
  gap: 8px;
}

.calendar-year-horizontal {
  display: grid;
  grid-template-rows: repeat(30, 64px);
  overflow: scroll;
  margin-top: 32px;
  gap: 4px;
  height: 512px;
}

.calendar-year-horizontal__row {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(34, 2.8%);
  height: 64px;
  background-color: var(--color__neutral-100);
  gap: 2px;
  margin-bottom: 4px;
}

.calendar-year-vertical {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  overflow: scroll;
  margin-top: 32px;
  grid-row-gap: 4px;
  grid-column-gap: 8px;
}

.calendar-year-vertical__column {
  display: grid;
  justify-items: center;
  grid-template-rows: repeat(33, 12.5px);
  gap: 3px;
  margin-bottom: 3px;
  height: 550px;
}

.calendar-simple-year-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 2em);
}

.calendar__simple-year-empty-entry {
  margin: 0.15em;
  background: var(--color__neutral-300);
  border: 0.01em solid #e7eaee;
  border-radius: 2px;
}

.calendar__simple-year-entry {
  margin: 0.15em;
  background: var(--color__success-400);
  border: 0.01em solid #e7eaee;
  border-radius: 2px;
}

/* Device it mouse pointer */
@media (hover: hover) {
  .calendar-year-vertical__column--inactive {
    opacity: 0.2;
  }

  .calendar-year-vertical__column:hover {
    opacity: 1;
  }
}

.calendar__item--year-vertical {
  background: var(--color__success-400);
  border: 1px solid #e7eaee;
  border-radius: 2px;
  height: 14px;
  width: 100%;
}

.calendar-year-vertical__month-label {
  grid-row-start: 1;
  grid-row-end: 2;
}

.calendar-year-horizontal__month-label {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-left: 8px;
}

.calendar-year-horizontal__year-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar__item--year-horizontal {
  background: var(--color__success-400);
  border: 1px solid #e7eaee;
  border-radius: 4px;
  height: 48px;
}

.calendar__nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar__nav--flex-container-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendar__nav--filter {
  width: 112px;
  height: 40px;
  color: var(--color__neutral-500);
  background-color: white;
  border: 1px solid var(--color__neutral-200);
  border-radius: 40px;
}

.retro__nav--filter {
  width: 212px;
  height: 40px;
  color: var(--color__neutral-500);
  background-color: white;
  border: 1px solid var(--color__neutral-200);
  border-radius: 40px;
}

.calendar__nav--streak-1 {
  display: inline;
  text-align: center;
  margin-right: 4px;
  color: var(--color__primary-800);
}

.calendar__nav--streak-2 {
  display: inline;
  text-align: center;
  color: var(--color__primary-800);
}

.calendar__nav--date-filter-wrapper {
  display: inline-flex !important;
  margin-right: 16px;
  margin-left: 16px;
}

/* override external compnente class */
.react-datepicker-wrapper {
  display: inline-flex !important;
}

/* override external compnente class */
.react-datepicker__input-container {
  display: inline-flex !important;
}

.calendar__item--month-grid {
  background: var(--color__success-200);
  border-radius: 8px;
  border: 2px solid #e7eaee;
}

.calendar__item--blurred {
  opacity: 0.2;
}

.calendar__item--calendar-text {
  color: white !important;
  font-size: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar__item--border {
  border: solid 1px var(--color__primary-700);
  cursor: pointer;
}

.calendar__item-day-string {
  padding-top: 1px;
  padding-left: 1px;
  padding-bottom: 4px;
  color: var(--color__success-900);
}

.calendar__item-time-string {
  padding-left: 2px;
  padding-bottom: 12px;
  color: var(--color__primary-900);
}

.calendar__item-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin-bottom: 16px;
  gap: 8px;
}

.calendar__item--no-entry-color--month-grid {
  background: var(--color__neutral-200);
}

.calendar__item--no-entry-color {
  background: var(--color__neutral-300);
}

.link--no-effect-white-text {
  background-color: var(--color__neutral-100) !important;
  text-decoration: none;
  border: none;
}

.link--no-effect-white-text:hover {
  color: #ffffff;
}

.link--no-effect {
  text-decoration: none;
}

.text--primary-purple-900 {
  color: var(--color__primary-900);
}

.text--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link--no-effect:hover {
  color: var(--color__primary-900);
}

.button--primary-action {
  background-color: var(--color__primary-800) !important;
  border-color: none;
  border-radius: 4px;
  color: white;
  text-decoration: none !important;
  padding: 12px 18px;
}

.signup-form-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 1.5em;
}

/* ###### general classes ###### */

/* SMALL SCREENS */
@media only screen and (max-width: 1250px) {
  .landingpage {
    margin-top: 64px;
  }
  .primary-button {
    width: 208px;
    height: 32px;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
  .landingpage__footer-subtext {
    margin-top: 48px;
    margin-bottom: 100px;
  }

  .primary-button {
    width: 278px;
    height: 43px;
  }
}

.user-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.user-icon-status {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.user-icon-status--online {
  background-color: var(--color__success-400);
  border: 2px solid white;
}

.user-icon-status--offline {
  background: var(--color__neutral-500);
  border: 2px solid white;
}

.primary-button {
  border: none;
  border-radius: 4px;
  /* color: white; */
  text-decoration: none !important;
}

.button-style-3 {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border-color: var(--color__primary-500);
  background: var(--gradient-3, linear-gradient(178deg, #7543e8 0%, #ff5b5b 100%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.w-100 {
  width: 100%;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.bg-none {
  background: none;
}

.border-none {
  border: none;
}

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.flex-item-aligned-start {
  align-self: flex-start;
}

.flex-item-aligned-center {
  align-self: center;
}

.flex-item-aligned-end {
  align-self: flex-end;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-gap-40 {
  gap: 40px;
}

.flex-gap-32 {
  gap: 32px;
}

.flex-gap-24 {
  gap: 24px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-16 {
  gap: 16px;
}

.flex-gap-12 {
  gap: 12px;
}

.flex-gap-8 {
  gap: 8px;
}

.flex-gap-6 {
  gap: 6px;
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-2 {
  gap: 2px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-top-128 {
  margin-top: 128px;
}

.margin-top-64 {
  margin-top: 64px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-left-64 {
  margin-left: 64px;
}

.padding-sides-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-sides-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-top-bottom-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-right-32 {
  padding-right: 32px;
}

.flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row-top-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-row-top-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text-align-start {
  text-align: flex-start;
}

.blinking-element {
  animation: blink 1s infinite;
}
