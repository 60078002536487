.single-entry__options {
  position: fixed;
  right: 3em;
  top: 4em;
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 8px;

  position: absolute;
  width: 169px;
  height: 212px;

  /* Shades/White */

  background: #ffffff;
  /* Shadow (XL) */

  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.color-picker-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
}

.report-issue-wrapper {
  width: 100%;
  margin-right: 8px;
}

.single-entry__options-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.meatball-menu {
  border-radius: 68px;
  width: 48px;
  height: 48px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
