.faq-container {
  grid-column: 2/12;
}

.faq-wrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: var(--color__neutral-50);
  border-radius: 16px;
  padding: 48px 0;
  margin-top: 96px;
}

.faq-text-wrapper {
  grid-column: 3/9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}
